import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-micro',
  templateUrl: './micro.component.html',
  styleUrls: ['./micro.component.css']
})
export class MicroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
